<template>
  <div id="App">
    <v-app>
      <v-main id="main">
        <top-header />
        <v-container fill-height>
          <v-row justify="center">
            <v-col cols="12" md="9">
              <v-card class="flex-nowrap" elevation="10">
                <transition name="fade" mode="out-in">
                  <router-view :key="$route.fullPath" />
                </transition>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import TopHeader from "./components/TopHeader.vue";
export default {
  name: "App",

  components: {
    TopHeader: TopHeader,
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" >
h2 {
  padding-top: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.5em;
}
h3 {
  padding-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 1em;
}
#main {
  background-color: white;
  background-image: url("./assets/pattern.png");
  background-size: cover;
}
strong {
  text-transform: uppercase;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-leave-active {
  transition: opacity 1s ease;
  opacity: 0;
}
</style>
