<template>
  <v-app-bar v-if="loggedIn" app color="" light>
    <!--     <span v-if="loggedIn">{{ user.uid }}</span>
 -->
    <!--  <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        transition="scale-transition"
        width="40"
      />

      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
        width="100"
      />
    </div> -->

    <v-spacer></v-spacer>

    <v-btn @click="signOut" text color="error">
      <span class="mr-2">Dalja</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { authFire } from "../firebase";

export default {
  name: "TopHeader",
  created() {
    authFire.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.loggedIn = true;
      } else {
        this.user = null;
        this.loggedIn = false;
      }
    });
  },
  data() {
    return {
      userName: null,
      userLastName: null,
      user: null,
      loggedIn: false,
    };
  },
  methods: {
    async signOut() {
      try {
        const data = await authFire.signOut();
        console.log(data);
        this.$router.replace({ name: "Authentication" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>