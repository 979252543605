import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBPOTCwr2ziWbyyNB5xlHU1PIptLNZQlPk",
    authDomain: "prishtina-grant-forms.firebaseapp.com",
    databaseURL: "https://prishtina-grant-forms.firebaseio.com",
    projectId: "prishtina-grant-forms",
    storageBucket: "prishtina-grant-forms.appspot.com",
    messagingSenderId: "1006795506914",
    appId: "1:1006795506914:web:daea34b518ca419ce20abb"
})

const authFire = firebaseApp.auth();

const db = firebaseApp.firestore();

export { db };
export { authFire };