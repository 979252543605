<template>
  <v-form v-model="valid" @submit.prevent="pressed">
    <v-row justify="end">
      <v-col cols="12" md="3">
        <v-img src="../../assets/logo.png"></v-img>
      </v-col>
      <v-col cols="12" md="8">
        <v-row justify="center">
          <v-col cols="12" md="10"> <h2>Regjistrimi</h2> </v-col>
        </v-row>
        <v-container text-right>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Fjalëkalimi"
                hint="Së paku 1 karakter i madhë, 1 simbol, 1 numër"
                counter
                @click:append="show1 = !show1"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="repassword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, rules.passwordMatch]"
                :type="show2 ? 'text' : 'password'"
                name="input-10-1"
                label="Konfirmo fjalëkalimin"
                hint="Së paku 1 karakter i madhë, 1 simbol, 1 numër"
                counter
                @click:append="show2 = !show2"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-btn type="submit" color="primary" x-large float-right
                >Regjistrohu</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
/* import { authFire } from "../../firebase";
 */
export default {
  methods: {
    async pressed() {
      alert("Afati për aplikime është përfunduar");

      /*     if (this.email == "") {
        alert("Hapsira për email nuk mund të jetë e zbrazët");
        return;
      }
      if (this.password != this.repassword) {
        alert("Fjalkalimet nuk po përshtaten");
        return;
      }

      if (
        //eslint-disable-next-line
        /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(this.password) ==
        false
      ) {
        alert("Fjalëkalimi duhet të ketë së paku 1 simbol");
        return;
      }
      var regex = new RegExp("[A-Z]+"); // Check for uppercase first
      if (regex.test(this.password) == false) {
        alert("Fjalëkalimi duhet të ketë së paku 1 shkornjë të madhe");
        return;
      }
      regex = new RegExp("[0-9]+"); // Now we check for numbers
      if (regex.test(this.password) == false) {
        alert("Fjalëkalimi duhet të ketë së paku 1 numër");
        return;
      }
      regex = new RegExp("[a-z]+"); // checking now for lowercase
      if (regex.test(this.password) == false) {
        alert("Fjalëkalimi duhet të ketë së paku 1 shkronjë të vogël");
        return;
      }

      try {
        const user = await authFire.createUserWithEmailAndPassword(
          this.email,
          this.password
        );

        authFire.currentUser.sendEmailVerification();
        console.log(user);
        alert("Emaili për verifikim është dërguar me sukses në " + this.email);

        this.$router.replace({ name: "UserForm" });
      } catch (error) {
        console.log(error);
      } */
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      email: "",
      password: "",
      repassword: "",
      rules: {
        required: (value) => !!value || "E detyrueshme.",
        min: (v) => v.length >= 8 || "Së paku 8 karaktere",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
h2 {
  margin-left: 8px;

  padding-top: 20px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 2em;
}
</style>
