<template>
  <div>
    <h3 class="text-center"></h3>
    <v-row justify="center">
      <v-col cols="12" md="3">
        <v-img src="../assets/logo_bw.png"></v-img>
      </v-col>
      <v-col cols="12" md="10">
        <h2 class="text-center">Aplikimi juaj është përfunduar me sukses.</h2>
        <h1 class="text-center">Ju faleminderit!</h1>
        <v-divider class="divider"></v-divider>

        <v-row justify="center">
          <v-btn
            large
            outlined
            color="error"
            type="button"
            class="button"
            @click="signOut"
            >Dalja</v-btn
          >
          <v-btn
            large
            color="primary"
            type="button"
            class="button"
            to="/Profile"
            >Profil</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { authFire } from "../firebase";

export default {
  data() {
    return {};
  },
  metaInfo: {
    title: "Prishtina Application Form Success!",
  },
  methods: {
    async signOut() {
      try {
        const data = await authFire.signOut();
        console.log(data);
        this.$router.replace({ name: "Authentication" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 1.4em;
  font-style: italic;
  text-align: center;
  padding: 0px;
  /*   text-transform: uppercase;
 */
  letter-spacing: 2px;
  font-weight: 400;
}
.button {
  margin: 16px 10px;
}
.divider {
  margin: 16px 0px;
}
</style>