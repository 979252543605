<template>
  <div class="home">
    <h2 class="text-center">mbështetja e artistëve të pavarur</h2>
    <h3 class="text-center">form 2/2</h3>
    <v-divider></v-divider>

    <v-form v-model="valid" @submit.prevent="create" id="applicationForm">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-row justify="space-around">
            <!-- <v-select
                v-model="formData.artType"
                :items="artTypes"
                label="Cilës fushë së artit i përkësni:"
                solo
              ></v-select> -->
            <!--               <h4 class="text-area-label">Cilës fushë të artit i përkisni:</h4>
 -->
            <v-col class="d-flex" cols="12" sm="12">
              <v-radio-group
                v-model="formData.artType"
                mandatory
                row
                label="Cilës fushë të artit i përkisni:"
              >
                <v-radio
                  v-for="type in artTypes"
                  :label="type"
                  :value="type"
                  :key="type.id"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="11" md="12">
              <h4 class="text-area-label">
                Ju lutem përshkruani se si ka ndikuar pandemia në mirëqenjen dhe
                punën tuaj. <br />(max. 400 fjalë)
              </h4>
              <v-textarea
                solo
                v-model="formData.ndikimi"
                :rules="rules"
                name="input-7-1"
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="11" md="12">
              <h4 class="text-area-label">
                Përshkruani një biografi të shkurtër tuajën.<br />(max. 400
                fjalë)
              </h4>
              <v-textarea
                solo
                v-model="formData.biografi"
                :rules="rules"
                name="input-7-1"
              ></v-textarea>
            </v-col>

            <v-col cols="11" md="12">
              <!-- <h4 class="text-area-label">
                A jeni i-e punësuar? (Në sektor publik, sektor privat,
                organizata ndërkombëtare apo organizata vendore.):
              </h4> -->

              <!--    <v-checkbox
                v-model="formData.iPunesuar"
                :label="'A jeni i-e punësuar? (Qoftë në punë të Shtetit, organizata ndërkombëtare apo private): '"
                required
              ></v-checkbox> -->
            </v-col>
            <v-col cols="11" md="12">
              <h4 class="text-area-label">
                Aktualisht a jeni në marrëdhënise pune? (Në institucione
                publike, organizata ndërkombtare, organizata të shoqërisë civile
                apo entitete private)
              </h4>
              <v-radio-group v-model="formData.iPunesuar" mandatory row>
                <v-radio label="Po" value="Po"></v-radio>
                <v-radio label="Jo" value="Jo"></v-radio>
              </v-radio-group>
              <v-textarea
                solo
                v-model="formData.pune"
                :rules="rules"
                name="input-7-1"
                placeholder="Përshkruani angazhimin tuaj"
                value="."
              ></v-textarea>
            </v-col>
            <v-col cols="11" md="12">
              <h4 class="text-area-label font-weight-bold">
                Ju lutemi ngarkoni këto dokumente:
              </h4>
              <div>
                <h4 class="text-area-label file-label">
                  (E detyrueshme) Rezumenë Kreative (CV)
                </h4>
                <v-btn
                  dark
                  :color="firebaseData['ResumeKreative'] ? 'green' : 'orange'"
                  @click="click1"
                >
                  <span v-if="firebaseData['ResumeKreative']"> Ndrysho </span>
                  <span v-else> Ngarko </span>
                  <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  dark
                  color="error"
                  v-if="firebaseData['ResumeKreative']"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <br />
                <span v-if="firebaseData['ResumeKreative']">
                  {{ firebaseData["ResumeKreativeFileName"] }}
                </span>
                <input
                  type="file"
                  ref="input1"
                  style="display: none"
                  @change="previewImage1"
                />
              </div>
              <v-divider></v-divider>
              <br />
              <div>
                <h4 class="text-area-label file-label">
                  (E detyrueshme) Portfolio e shkurtër e punëve të realizuara
                  gjatë vitit 2018-2020
                </h4>
                <v-btn
                  dark
                  :color="firebaseData['Portfolio'] ? 'green' : 'orange'"
                >
                  <span v-if="firebaseData['Portfolio']"> Ndrysho </span>
                  <span v-else> Ngarko </span>
                  <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  dark
                  color="error"
                  @click="delete2"
                  v-if="firebaseData['Portfolio']"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <br />
                <span v-if="firebaseData['Portfolio']">
                  {{ firebaseData["PortfolioFileName"] }}
                </span>
                <input
                  type="file"
                  ref="input2"
                  style="display: none"
                  @change="previewImage2"
                />
              </div>
              <v-divider></v-divider>

              <br />
              <div>
                <h4 class="text-area-label file-label">
                  (E detyrueshme) Letërnjoftimi (Kopja e letërnjoftimit në të
                  dyja anët)
                </h4>
                <v-btn dark color="orange">
                  <span> Ngarko </span>
                  <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  dark
                  color="error"
                  @click="delete3"
                  v-if="firebaseData['Leternjoftimi']"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <br />
                <span v-if="firebaseData['Leternjoftimi']">
                  {{ firebaseData["LeternjoftimiFileName"] }}
                </span>
                <input
                  type="file"
                  ref="input3"
                  style="display: none"
                  @change="previewImage3"
                />
              </div>
              <v-divider></v-divider>

              <br />
              <div>
                <h4 class="text-area-label file-label">
                  (E detyrueshme) Vërtetimi i xhirollogarisë bankare rrjedhëse
                </h4>
                <v-btn
                  dark
                  :color="firebaseData['DeshmiBanka'] ? 'green' : 'orange'"
                >
                  <span v-if="firebaseData['DeshmiBanka']"> Ndrysho </span>
                  <span v-else> Ngarko </span>
                  <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  dark
                  color="error"
                  @click="delete4"
                  v-if="firebaseData['DeshmiBanka']"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <br />
                <span v-if="firebaseData['DeshmiBanka']">
                  {{ firebaseData["DeshmiBankaFileName"] }}
                </span>
                <input
                  type="file"
                  ref="input4"
                  style="display: none"
                  @change="previewImage4"
                />
              </div>
              <v-divider></v-divider>

              <br />
              <div>
                <h4 class="text-area-label file-label">
                  (Opcionale) Dokument-e për aktivitete të planifikuara, por të
                  cilat janë anuluar për shkak të pandemisë gjatë vitit 2020.
                </h4>
                <v-btn dark color="orange">
                  <span> Ngarko </span>
                  <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  dark
                  color="error"
                  @click="delete5"
                  v-if="firebaseData['AktivitetetPlan']"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <br />
                <span v-if="firebaseData['AktivitetetPlan']">
                  {{ firebaseData["AktivitetetPlanFileName"] }}
                </span>
                <input
                  type="file"
                  ref="input5"
                  style="display: none"
                  @change="previewImage5"
                />
              </div>
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" md="12">
              <v-checkbox
                v-model="formData.deklarata"
                :label="'Përmes kësaj deklarate, deklaroj se nuk kam pasë të hyra totale mbi 1,000.00 euro prej Prill - Nëntor 2020.'"
                required
              ></v-checkbox>
            </v-col>

            <v-row justify="center">
              <v-col cols="4" md="6" class="text-left">
                <v-btn
                  large
                  text
                  class="submit-button"
                  type="button"
                  float-right
                  @click="$router.back()"
                >
                  <v-icon dark left> mdi-arrow-left </v-icon>
                  Kthehu
                </v-btn>
              </v-col>

              <v-col cols="4" md="6" class="text-right">
                <v-btn
                  large
                  class="submit-button"
                  color="primary"
                  type="submit"
                  form="applicationForm"
                  float-right
                  >Përfundo
                  <v-icon right dark> mdi-content-save </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { db } from "../firebase";
import { authFire } from "../firebase";
import firebase from "firebase";

export default {
  metaInfo: {
    title: "Prishtina Application Form",
  },
  data() {
    return {
      artTypes: [
        "Arte Vizuele",
        "Edukim",
        "Hulumtim",
        "Kinematografi",
        "Letërsi",
        "Muzikë",
        "Teatër",
        "Vallëzim",
      ],
      dialog: false,
      firebaseData: null,
      rules: [(v) => v.split(" ").length <= 400 || "Max 400 fjalë"],
      formData: {
        ndikimi: " ",
        biografi: " ",
        pune: " ",
      },
    };
  },
  firestore() {
    return {
      firebaseData: db.doc("users/" + authFire.currentUser.uid),
    };
  },
  methods: {
    async create() {
      alert("Afati për aplikime është përfunduar");
      this.$router.replace({ name: "Profile" });

      /*   if (this.formData.deklarata == false || this.formData.deklarata == null) {
        alert("Duhet ta pranoni deklaratën.");
        return;
      }
      if (this.firebaseData["ResumeKreative"] != true) {
        alert("Ngarkoni Rezumenë Kreative (CV)");
        return;
      }
      if (this.firebaseData["Portfolio"] != true) {
        alert(
          "Ngarkoni nje portfolio të shkurtër e punëve të realizuara gjatë vitit 2018-2019"
        );
        return;
      }
      if (this.firebaseData["Leternjoftimi"] != true) {
        alert(
          "Ngarkoni letërnjoftimin (Kopja e letërnjoftimit në të dyja anët)"
        );
        return;
      }
      if (this.firebaseData["DeshmiBanka"] != true) {
        alert("Ngarkoni vërtetimin që keni xhirollogari bankare rrjedhëse");
        return;
      }

      /*    if (this.formData.ndikimi.split(" ").length >= 400) {
        alert("Paragrafet nuk guxojn te ken ma shume se 400 fjalë");
        return;
      }

      if (this.formData.biografi.split(" ").length >= 400) {
        alert("Paragrafet nuk guxojn te ken ma shume se 400 fjalë");
        return;
      }

      if (this.formData.pune.split(" ").length >= 400) {
        alert("Paragrafet nuk guxojn te ken ma shume se 400 fjalë");
        return;
      } */
      /*
      try {
        await db.doc("users/" + authFire.currentUser.uid).update({
          artType: this.formData.artType ?? "empty",
          biografi: this.formData.biografi ?? "empty",
          deklarata: this.formData.deklarata ?? "empty",
          iPunesuar: this.formData.iPunesuar ?? "empty",
          ndikimi: this.formData.ndikimi ?? "empty",
          pune: this.formData.pune ?? "empty",
          allSaves: firebase.firestore.FieldValue.arrayUnion(Date()) ?? "empty",
          lastSave: Date() ?? "empty",
        });

        console.log("Aplikimi juaj është përfunduar me sukses!");
        this.$router.replace({ name: "ApplicationComplete" });
      } catch (error) {
        alert("Ju lutemi provoni përsëri pak më vonë.");
        console.log(error);
      } */
    },

    click1() {
      this.$refs.input1.click();
    },

    previewImage1(event) {
      this.imageData = event.target.files[0];
      this.onUpload1();
    },

    async onUpload1() {
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ ResumeKreative: true });
      this.file1 = null;
      const storageRef = firebase
        .storage()
        .ref(
          authFire.currentUser.uid + "/ResumeKreative/" + this.imageData.name
        )
        .put(this.imageData);
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ ResumeKreativeFileName: this.imageData.name });
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            this.file1 = url;
            await db
              .doc("users/" + authFire.currentUser.uid)
              .update({ ResumeKreativeURL: url });
            console.log(this.file1);
          });
        }
      );
      alert("File-i është ngarkuar");
    },
    async delete1() {
      await db.doc("users/" + authFire.currentUser.uid).update({
        ResumeKreativeURL: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        ResumeKreativeFileName: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        ResumeKreative: false,
      });
    },

    click2() {
      this.$refs.input2.click();
    },

    previewImage2(event) {
      this.imageData = event.target.files[0];
      this.onUpload2();
    },

    async onUpload2() {
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ Portfolio: true });
      this.file2 = null;
      const storageRef = firebase
        .storage()
        .ref(authFire.currentUser.uid + "/Portfolio/" + this.imageData.name)
        .put(this.imageData);
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ PortfolioFileName: this.imageData.name });

      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            this.file2 = url;
            await db
              .doc("users/" + authFire.currentUser.uid)
              .update({ PortfolioURL: url });

            console.log(this.file1);
          });
        }
      );
      alert("File-i është ngarkuar");
    },
    async delete2() {
      await db.doc("users/" + authFire.currentUser.uid).update({
        PortfolioURL: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        PortfolioFileName: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        Portfolio: false,
      });
    },
    click3() {
      this.$refs.input3.click();
    },

    previewImage3(event) {
      this.imageData = event.target.files[0];
      this.onUpload3();
    },

    async onUpload3() {
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ Leternjoftimi: true });
      this.file3 = null;
      const storageRef = firebase
        .storage()
        .ref(authFire.currentUser.uid + "/Leternjoftimi/" + this.imageData.name)
        .put(this.imageData);
      await db.doc("users/" + authFire.currentUser.uid).update({
        LeternjoftimiFileName: firebase.firestore.FieldValue.arrayUnion(
          this.imageData.name
        ),
      });
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            this.file3 = url;
            await db.doc("users/" + authFire.currentUser.uid).update({
              LeternjoftimiURL: firebase.firestore.FieldValue.arrayUnion(url),
            });

            console.log(this.file3);
          });
        }
      );
      alert("File-i është ngarkuar");
    },
    async delete3() {
      await db.doc("users/" + authFire.currentUser.uid).update({
        LeternjoftimiURL: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        LeternjoftimiFileName: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        Leternjoftimi: false,
      });
    },
    click4() {
      this.$refs.input4.click();
    },

    previewImage4(event) {
      this.imageData = event.target.files[0];
      this.onUpload4();
    },

    async onUpload4() {
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ DeshmiBanka: true });
      this.file4 = null;
      const storageRef = firebase
        .storage()
        .ref(authFire.currentUser.uid + "/DeshmiBanka/" + this.imageData.name)
        .put(this.imageData);
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ DeshmiBankaFileName: this.imageData.name });
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            this.file4 = url;
            await db
              .doc("users/" + authFire.currentUser.uid)
              .update({ DeshmiBankaURL: url });

            console.log(this.file4);
          });
        }
      );
      alert("File-i është ngarkuar");
    },
    async delete4() {
      await db.doc("users/" + authFire.currentUser.uid).update({
        DeshmiBankaURL: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        DeshmiBankaFileName: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        DeshmiBanka: false,
      });
    },
    click5() {
      this.$refs.input5.click();
    },

    previewImage5(event) {
      this.imageData = event.target.files[0];
      this.onUpload5();
    },

    async onUpload5() {
      await db
        .doc("users/" + authFire.currentUser.uid)
        .update({ AktivitetetPlan: true });
      this.file5 = null;
      const storageRef = firebase
        .storage()
        .ref(
          authFire.currentUser.uid + "/AktivitetetPlan/" + this.imageData.name
        )
        .put(this.imageData);
      await db.doc("users/" + authFire.currentUser.uid).update({
        AktivitetetPlanFileName: firebase.firestore.FieldValue.arrayUnion(
          this.imageData.name
        ),
      });
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            this.file5 = url;
            await db.doc("users/" + authFire.currentUser.uid).update({
              AktivitetetPlanURL: firebase.firestore.FieldValue.arrayUnion(url),
            });

            console.log(this.file5);
          });
        }
      );
      alert("File-i është ngarkuar");
    },
    async delete5() {
      await db.doc("users/" + authFire.currentUser.uid).update({
        AktivitetetPlanURL: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        AktivitetetPlanFileName: null,
      });
      await db.doc("users/" + authFire.currentUser.uid).update({
        AktivitetetPlan: false,
      });
    },
  },
  created: async function () {
    const docRef = db.doc("users/" + authFire.currentUser.uid);

    let data = (await docRef.get()).data();

    if (!data) {
      data = {};
      docRef.set(data);
    }

    this.formData = data;
    this.state = "synced";
  },
};
</script>
<style lang="scss" scoped>
h4 {
  font-weight: 300;
}
.page-no {
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.submit-button {
  margin-bottom: 20px;
}

.text-area-label {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}

.file-label {
  font-weight: 600;
  font-style: italic;
}
</style>