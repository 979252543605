<template>
  <div>
    <h2 class="text-center">Verifiko email adresën</h2>
    <h3 class="text-center"></h3>
    <v-divider></v-divider>
    <v-row justify="center" class="row">
      <span
        >Nëse nuk e keni pranuar emailin verifikues sigurohuni që nuk është në
        folder-in junk/spam,<br />
        nëse ende nuk e keni gjetur emailin
        <a class="link" @click="sendConfirm"> klikoni këtu. </a>
      </span>
    </v-row>
    <v-row justify="center" class="row">
      <v-btn large color="primary" class="button" type="button" href="/userform"
        >Konfirmo</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import { authFire } from "../firebase";

export default {
  metaInfo: {
    title: "Verify Mail",
  },
  methods: {
    sendConfirm() {
      try {
        authFire.currentUser.sendEmailVerification();
        alert(
          "Emaili për verifikim është dërguar me sukses në " +
            authFire.currentUser.email
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin-bottom: 16px;
}
span {
  font-size: 1.1em;
  margin: 8px 0px;
}
.link {
  font-weight: 600;
  color: rgb(17, 65, 155);
  &:hover {
    color: rgb(16, 77, 192);
  }
}
</style>