import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '../views/Authentication.vue'
//import AdminPanel from '../views/AdminPanel.vue'
import ApplicationForm from '../views/ApplicationForm.vue'
import ApplicationComplete from '../views/ApplicationComplete.vue'
import Profile from '../views/Profile.vue'
import UserForm from '../views/UserForm.vue'
import VerifyMail from '../views/VerifyMail.vue'
import { authFire } from "../firebase";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Authentication,
    meta:{requiresNoAuth:true}
    
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: Authentication,
    meta:{requiresNoAuth:true}
  },
/*   {
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {requiresAdmin:true, requiresAuth:true}
  }, */
  {
    path: '/applicationform',
    name: 'ApplicationForm',
    component: ApplicationForm,
    meta:{requiresAuth:true},
  },
  {
    path: '/userform',
    name: 'UserForm',
    component: UserForm,
    meta:{requiresAuth:true},
  },
    {
    path: '/ApplicationComplete',
    name: 'ApplicationComplete',
    component: ApplicationComplete,
    meta:{requiresAuth:true},
  },
     {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta:{requiresAuth:true},
  },    
   {
    path: '/verifymail',
    name: 'VerifyMail',
    component: VerifyMail,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = authFire.currentUser;
  const user = authFire.currentUser

  
  if(requiresAuth && isAuthenticated && !user.emailVerified){
    next('/verifymail')
    return
  }

  else if (requiresAuth && !isAuthenticated) {
    next("/authentication");
    return
  }
  else if(requiresNoAuth && isAuthenticated){
    next("/profile");
    return
  }
  else{
    next();
  }
    
  
})

export default router
