<template>
  <v-form v-model="valid" @submit.prevent="pressed">
    <v-row justify="end">
      <v-col cols="12" md="3">
        <v-img src="../../assets/logo.png"></v-img>
      </v-col>
      <v-col cols="12" md="8">
        <v-row justify="center">
          <v-col cols="12" md="10"> <h2>Hyrja</h2> </v-col>
        </v-row>
        <v-container text-right>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Fjalëkalimi"
                hint="Së paku 8 karaktere"
                counter
                @click:append="show1 = !show1"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-btn type="submit" color="primary" x-large float-right
                >kyqu</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { authFire } from "../../firebase";

export default {
  data() {
    return {
      show1: false,
      email: "",
      password: "",
      rules: {
        required: (value) => !!value || "E detyrueshme.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "Emaili dhe fjalëkalimi nuk po përshtaten",
      },
    };
  },
  validations: {
    email: {
      required: true,
    },
  },
  methods: {
    async pressed() {
      if (this.email == "") {
        alert("Hapsira për email nuk mund të jetë e zbrazët");
        return;
      }
      if (this.password == "") {
        alert("Hapsira për fjalkalim nuk mund të jetë e zbrazët");
        return;
      }
      try {
        const user = await authFire.signInWithEmailAndPassword(
          this.email,
          this.password
        );
        this.$router.replace({ name: "UserForm" });
        console.log(user);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  margin-left: 8px;
  padding-top: 20px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 2em;
}
</style>

