import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { firestorePlugin } from 'vuefire';
import { authFire } from "./firebase";
import Vuelidate from "vuelidate"
import VueMeta from 'vue-meta'
import { VueMaskDirective } from 'v-mask'

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(firestorePlugin)
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false

let app;
authFire.onAuthStateChanged(user=>{
  console.log(user);
  if (!app) {
    app = new Vue({
          router,
          store,
          vuetify,
          render: h => h(App)
    }).$mount('#app')
  }
})
