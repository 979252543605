<template>
  <div class="home">
    <h2 class="text-center">mbështetja e artistëve të pavarur</h2>
    <h3 class="text-center">form 1/2</h3>
    <v-divider></v-divider>
    <v-form v-model="valid" @submit.prevent="create" id="creationForm">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-row justify="space-around">
            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="formData.firstname"
                :rules="nameRules"
                label="Emri"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="formData.lastname"
                :rules="nameRules"
                label="Mbiemri"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-card-account-details-outline"
                v-model="formData.id"
                :rules="nameRules"
                label="Nr. Personal identifikues:"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-map"
                v-model="formData.address"
                :rules="nameRules"
                label="Adresa:"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="11" md="12">
              <h4 class="text-area-label">
                A jeni qytetar-e i komunës së Prishtinës, i-e regjistruar në
                regjistrin civil:
              </h4>
              <v-radio-group v-model="formData.banoreIPrishtines" mandatory row>
                <v-radio label="Po" value="Po"></v-radio>
                <v-radio label="Jo" value="Jo"></v-radio>
              </v-radio-group>
              <!-- <v-checkbox
                v-model="formData.banoreIPrishtines"
                :label="'A jeni qytetar i komunës së Prishtinë, i-e regjistruar ne regjistrin civil: (po/jo)'"
                required
              ></v-checkbox> -->
            </v-col>
            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-phone"
                v-model="formData.telNo"
                :rules="nameRules"
                label="Numri kontaktues"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="11" md="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    v-model="formData.birthDate"
                    label="Data e lindjës"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker v-model="formData.birthDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="11" md="6">
              <v-select
                v-model="formData.bankName"
                :menu-props="{ maxHeight: '800' }"
                dense
                filled
                prepend-icon="mdi-bank"
                :items="items"
                label="Zgjedhni Bankën"
              ></v-select>
            </v-col>
            <v-col cols="11" md="6">
              <v-text-field
                prepend-icon="mdi-form-textbox-password"
                v-model="formData.bankNo"
                :rules="nameRules"
                label="Numri i xhirollogarisë"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="11" md="12" class="text-right">
              <v-btn
                prepend-icon="mdi-arrow-right"
                large
                class="submit-button"
                color="primary"
                type="submit"
                form="creationForm"
                float-right
                >Vazhdo
                <v-icon right dark> mdi-arrow-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { db } from "../firebase";
import { authFire } from "../firebase";

export default {
  metaInfo: {
    title: "Prishtina User Form",
  },
  data() {
    return {
      firebaseData: null,
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      formData: {
        banoreIPrishtines: false,
      },
      items: [
        "ProCredit Bank LTD.",
        "Banka e Re e Kosovës",
        "Banka për Biznes SH.A.",
        "Banka Ekonomike e Kosovës",
        "Raiffeisen Bank Kosovë J.S.C.",
        "Banka Kreditore e Prishtinës",
        "NLB Prishtina SH.A",
        "Banka Kombëtare Tregtare",
        "Turk Ekonomi Bankasi SH.A",
        "Turkiye IS Bankasi",
        "ZIRAAT BANK",
        "Komercijalna Banka",
      ],
    };
  },
  firestore() {
    return {
      firebaseData: db.doc("users/" + authFire.currentUser.uid),
    };
  },
  methods: {
    async create() {
      alert("Afati për aplikime është përfunduar");
      this.$router.push({ name: "ApplicationForm" });

      /*     try {
        await db.doc("users/" + authFire.currentUser.uid).update(this.formData);
        await db.doc("users/" + authFire.currentUser.uid).update({
          isAdmin: false,
          accountIsCreated: true,
        });

        this.$router.push({ name: "ApplicationForm" });
      } catch (error) {
        console.log(error);
      } */
    },
  },
  created: async function () {
    const docRef = db.doc("users/" + authFire.currentUser.uid);

    let data = (await docRef.get()).data();

    if (!data) {
      data = {};
      docRef.set(data);
    }

    this.formData = data;
    this.state = "synced";
  },
};
</script>
<style lang="scss" scoped>
span {
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.submit-button {
  margin-bottom: 20px;
}

.text-area-label {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}
</style>
