<template>
  <div class="Authentication">
    <h2 class="text-center">mbështetja e artistëve të pavarur</h2>
    <v-divider></v-divider>

    <v-btn color="purple" dark @click="toggle" xw class="text-right mybutton">
      <span v-if="isRegister"><v-icon left>mdi-arrow-left</v-icon> Hyrje </span>
      <span v-else> <v-icon left>mdi-arrow-left</v-icon> Regjistrim </span>
    </v-btn>
    <transition name="component-fade" mode="out-in">
      <component v-bind:is="view"></component>
    </transition>
    <!-- <Login v-show="!isRegister" />
    <Register v-show="isRegister" /> -->
  </div>
</template>

<script>
import Register from "../components/authentication/Register";
import Login from "../components/authentication/Login";

export default {
  metaInfo: {
    title: "Prishtina Grant Authentication",
  },
  components: {
    Login: Login,
    Register: Register,
  },
  data() {
    return {
      view: "Register",
      isRegister: true,
    };
  },
  methods: {
    toggle: function () {
      this.isRegister = !this.isRegister;
      if (this.view == "Register") {
        this.view = "Login";
      } else {
        this.view = "Register";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.5em;
}

.mybutton {
  margin-right: 8px;
  margin-top: 8px;
  float: right;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
