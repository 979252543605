<template>
  <div>
    <h2 class="text-center">Aplikanti - {{ email }}</h2>
    <h3 class="text-center"></h3>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="12" md="3">
        <v-img src="../assets/logo_bw.png"></v-img>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-btn class="aplikimi" outlined large color="error" @click="signOut"
            >Dalja</v-btn
          >
          <v-btn class="aplikimi" large color="primary" to="/userform"
            >shiko Aplikimin</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { authFire } from "../firebase";

export default {
  metaInfo: {
    title: "Profile",
  },
  data() {
    return {
      email: authFire.currentUser.email,
    };
  },
  methods: {
    async signOut() {
      try {
        const data = await authFire.signOut();
        console.log(data);
        this.$router.replace({ name: "Authentication" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-style: italic;
  text-align: center;
  padding: 16px;
  /*   text-transform: uppercase;
 */
  letter-spacing: 2px;
  font-weight: 400;
}
.aplikimi {
  margin: 16px;
}
</style>